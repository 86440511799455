<template>
  <div class="scan-results view">
    <app-header :title="__('view.ingame.scan_result.title')"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{__('common.total')}}:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <div class="control-bar__search mr ml">
        <select-reference
            v-model="stellarObjectId"
            :field-icon="'icon'"
            :endpoint="'/ingame/world/stellarobject'"
            :nothing-selected-message="__('filterByStellarObject')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <div class="control-bar__search mr">
        <select-reference
            v-model="oreId"
            :endpoint="'/ingame/items/item'"
            :field-icon="'icon'"
            :field-title="'fullName'"
            :nothing-selected-message="__('filterByOre')"
            :hide-info="true"
            @input="refreshDelayed(100)"
        />
      </div>
      <text-field
          class="control-bar__search mr"
          v-model="minVolume"
          :label="__('minVolume')"
          min="0"
          :hide-details="true"
          :clearable="true"
          :type="'number'"
          @keyup:enter="refreshDelayed(100)"
          @click:clear="refreshDelayed(100)"
      />
      <text-field
          class="control-bar__search"
          v-model="searchTerm"
          :label="__('common.search_term')"
          max-length="50"
          :hide-details="true"
          :clearable="true"
          @keyup:enter="refreshDelayed(100)"
          @click:clear="refreshDelayed(100)"
      />
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
        <div class="control-bar__button" :title="__('common.add')" @click="addScanResult" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/add.svg"
               alt="add"/>
        </div>
      </div>
    </div>
    <div class="scroll-horizontal-overflow">
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <data-table
          :records="scanResults"
          :fields="dataTableFields"
          :record-actions="recordActions"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loading"
          @copyCoordinates="copyCooardinates"
          @editScanResult="editScanResult"
          @sort="sort"
          @deleteRecord="deleteRecord"
      />
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
    </div>
    <input type="text" id="coordinates_clipboard" style="display: none"/>
    <edit-scan-result ref="editScanResult" @updated="refresh" @created="editScanResult"/>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import apiSecured from '../../api/secured';
  import DataTable from '../../components/DataTable';
  import AppHeader from '../../components/AppHeader';
  import EditScanResult from '../../components/Ingame/EditScanResult';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { paginationMixin } from "../../mixins/pagination";
  import { pageTitleMixin } from "../../mixins/pageTitle";
  import { numberToLocaleString } from "../../helpers/itemHelpers";

  const renderResources = (value) => {
    let html = '';
    value.forEach(resource => {
      html += `<div class="data-table__ore-chip"><div class="data-table__ore-chip-icon" style="background-image: url('${resource.ore.icon}')" title="${resource.ore.name}"></div><div class="data-table__ore-chip-value">${numberToLocaleString(resource.volume / 1000,0,0)} k</div></div>`
    });
    return `<div class="data-table__ore-chips">${html}</div>`;
  }

  export default {
    mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin],
    components: {
      AppHeader,
      DataTable,
      EditScanResult
    },
    data: () => ({
      loading: false,
      scanResults: [],
      dataTableFields: [],
      recordActions: [],
      stellarObjectId: null,
      oreId: null,
      minVolume: null,
    }),
    methods: {
      numberToLocaleString: numberToLocaleString,
      copyCooardinates(record) {
        const copyText = document.getElementById('coordinates_clipboard');
        copyText.style.display = 'block';
        copyText.value = record.pos;
        copyText.select();
        document.execCommand("copy");
        copyText.style.display = 'none';
        this.showSuccessNotification(copyText.value);
      },
      refreshDelayed(delay) {
        setTimeout(this.refresh, delay);
      },
      refresh() {
        this.loading = true;
        let params = this.getPaginationParams();
        params.stellarObjectId = this.stellarObjectId;
        params.oreId = this.oreId;
        params.minVolume = this.minVolume;
        this.$router.push({ name: this.$route.name, query: params }).catch(err => {
        });
        apiSecured.get('/ingame/world/scanresult', { params }).then(async (res) => {
          this.scanResults = res.data;
          await this.enrichRecords();
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification(this.__('common.scan_result_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async enrichRecords() {
        const stellarObjectIds = this.scanResults.filter(record => record.stellarObjectId !== null).map(record => record.stellarObjectId);
        if (stellarObjectIds.length > 0) {
          await apiSecured.get(`/ingame/world/stellarobject/(${stellarObjectIds.join(',')})`, {
            params: {
              fields: 'id,name,icon'
            },
          }).then((res) => {
            this.scanResults = this.scanResults.map(record => {
              const stellarObject = res.data.find(resRecord => resRecord.id === record.stellarObjectId);
              record.stellarObjectName = stellarObject.name;
              record.stellarObjectIcon = stellarObject.icon;
              return record;
            });
            this.showInfoNotification('Stellar objects have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }
        const oreIds = [];
        this.scanResults.forEach(scanResult => {
          scanResult.resources.forEach(resource => {
            oreIds.push(resource.oreId);
          });
        });
        if (oreIds.length > 0) {
          await apiSecured.get(`/ingame/items/item/(${oreIds.join(',')})`, {
            params: {
              fields: 'id,name,icon,tier,mass'
            },
          }).then((res) => {
            this.scanResults = this.scanResults.map(scanResult => {
              scanResult.resources = scanResult.resources.map(resource => {
                resource.ore = res.data.find(ore => ore.id === resource.oreId);
                return resource;
              }).sort((a,b) => a.ore.mass - b.ore.mass).sort((a,b) => a.ore.tier - b.ore.tier);
              return scanResult;
            });
            this.showInfoNotification('Ores have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }
      },
      addScanResult() {
        this.$refs.editScanResult.showCreate();
      },
      editScanResult(scanResult) {
        this.$refs.editScanResult.showEdit(scanResult);
      },
      deleteRecord(record) {
        Swal.fire({
          title: this.__('common.are_you_sure'),
          text: this.__('common.do_you_want_to_delete'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.__('common.yes_delete_it')
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            let endpoint = '/ingame/world/scanresult/delete/' + record.id;
            apiSecured.delete(endpoint).then((res) => {
              this.loading = false;
              this.showSuccessNotification(this.__('common.deleted'));
              this.refresh();
            }).catch((error) => {
              this.loading = false;
              this.showErrorNotifications(error);
              this.refresh();
            });
          }
        });
      },
    },
    created() {
      if(this.$route.query.stellarObjectId) {
        this.stellarObjectId = this.$route.query.stellarObjectId;
      }
      if(this.$route.query.oreId) {
        this.oreId = this.$route.query.oreId;
      }
      if(this.$route.query.minVolume) {
        this.minVolume = this.$route.query.minVolume;
      }
      this.setPageTitle(this.__('entity.common.scan_results'));
      this.dataTableFields = [
        { name: "id", title: this.__('entity.common.id'), type: "default" },
        { name: "tile", title: this.__('entity.common.tile'), type: "numeric" },
        { name: "pos", title: 'Pos', type: "default" },
        { name: "resources", title: 'Resources', type: "custom", render: renderResources },
        { name: "stellarObjectIcon", title: '', type: "image", disableSorting: true },
        { name: "stellarObjectName", title: this.__('entity.common.stellar_object'), type: "default", disableSorting: true },
        { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
        { name: "updatedAt", title: this.__('entity.common.updated_at'), type: "datetime" }
      ];
      this.recordActions = [
        { title: this.__('view.ingame.constructs.copy_coordinates'), icon: 'copy', event: 'copyCoordinates' },
        { title: this.__('component.ingame.edit_scan_result.title'), icon: 'pencil', event: 'editScanResult' },
        {
          title: this.__('view.ingame.players.delete_scan_result'),
          icon: 'bin',
          event: 'deleteRecord',
          restrictRoles: []
        }
      ];
      this.refresh();
    }
  }
</script>
