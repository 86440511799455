<template>
  <div class="original-scan-result">
    <h2>Original Scan Result</h2>
    <table class="object-table" v-if="originalScanResult">
      <tr>
        <td>{{__('entity.common.id')}}:</td>
        <td>{{ originalScanResult.id}}</td>
      </tr>
      <tr>
        <td>{{__('entity.common.title')}}:</td>
        <td>{{ originalScanResult.tile }}</td>
      </tr>
      <tr>
        <td>{{__('entity.common.stellar_object')}}:</td>
        <td v-if="originalScanResult.stellarObjectId">{{ originalScanResult.stellarObjectId }}</td>
        <td v-else>-</td>
      </tr>
      <tr>
        <td>{{__('entity.common.description')}}:</td>
        <td>{{ originalScanResult.description }}</td>
      </tr>
      <tr>
        <td>{{__('entity.common.created_at')}}:</td>
        <td>{{ originalScanResult.createdAt }}</td>
      </tr>
      <tr>
        <td>{{__('entity.common.updated_at')}}:</td>
        <td>{{ originalScanResult.updatedAt }}</td>
      </tr>
    </table>
    <p v-else>{{__('component.ingame.edit_scan_result.save_first')}}</p>
  </div>
</template>

<script>
  import { localizationMixin } from '../../../mixins/localization';

  export default {
    mixins: [localizationMixin],
    props: ['originalScanResult']
  }
</script>