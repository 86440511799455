<template>
  <div class="edit-scan-result-resources" :key="rerenderKey">
    <progress-linear v-if="loading"/>
    <h2>{{__('component.ingame.edit_scan_result.scan_result_resources.title')}}</h2>
    <template v-if="scanResultId !== null">
      <table class="editable-table">
        <tr v-for="(scanResultResource, index) in scanResultResources" :key="'scan_result_resource_'+index">
          <td v-if="scanResultResource.ore"><img :src="scanResultResource.ore.icon" alt="-"/></td>
          <td v-if="scanResultResource.ore">{{ scanResultResource.ore.name }}</td>
          <td>
            <text-field
                v-model="scanResultResource.volume"
                :label="__('entity.common.volume_in_liters')"
                :type="'number'"
                maxlength="10" min="0"
                :hide-details="true"
                :prepend-icon="getPrependIcon(scanResultResource)"
                @input="registerChange(scanResultResource)"
            />
          </td>
          <td class="editable-table__button" @click="toggleDeleted(scanResultResource)">
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/bin.svg" alt="x"/>
          </td>
        </tr>
      </table>
      <div class="layout">
        <div class="flex xs6 mt">
          <div class="btn btn--success" :loading="loading" :disabled="!hasChanges()" @click="saveScanResultResources"
               v-slashes>{{ __('common.save_changes') }}
          </div>
        </div>
        <div class="flex xs6 mt">
          <div class="btn btn--info" :loadding="loading" @click="showOreBrowser" v-slashes>
            {{__('component.ingame.edit_scan_result.scan_result_resources.add_scan_result_resources')}}
          </div>
        </div>
      </div>
    </template>
    <p v-else>{{__('component.ingame.edit_scan_result.scan_result_resources.save_item_first')}}</p>
    <ore-browser ref="oreBrowser" @oreSelected="addOre"/>
  </div>
</template>

<script>
  import apiSecured from '../../../api/secured';
  import { notificationsMixin } from '../../../mixins/notifications';
  import { rerenderMixin } from "../../../mixins/rerender";
  import { localizationMixin } from '../../../mixins/localization';
  import OreBrowser from "../Common/OreBrowser";

  export default {
    components: { OreBrowser },
    mixins: [notificationsMixin, rerenderMixin, localizationMixin],
    data: () => ({
      loading: false,
      scanResultResources: [],
      inProgress: 0,
      rerenderKey: 'no-set'
    }),
    props: ['scanResultId'],
    watch: {
      scanResultId() {
        this.refresh();
      }
    },
    created() {
      this.refresh();
    },
    methods: {
      hasChanges() {
        return this.scanResultResources.filter((scanResultResource) => {
          return this.isNew(scanResultResource) || this.isChanged(scanResultResource) || this.isDeleted(scanResultResource);
        }).length > 0;
      },
      refresh() {
        this.loading = true;
        this.scanResultResources = [];
        if (this.scanResultId === null) {
          this.loading = false;
          return;
        }
        apiSecured.get('/ingame/world/scanresultresource/by-scan-result/' + this.scanResultId).then(async (res) => {
          this.scanResultResources = res.data;
          await this.enrichRecords();
          this.loading = false;
          this.showInfoNotification(this.__('common.scan_result_resources_loaded'))
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async enrichRecords() {
        const oreIds = this.scanResultResources.filter(record => record.oreId !== null).map(record => record.oreId);
        if (oreIds.length > 0) {
          await apiSecured.get(`/ingame/items/item/(${oreIds.join(',')})`, {
            params: {
              fields: 'id,icon,name'
            },
          }).then((res) => {
            this.scanResultResources = this.scanResultResources.map(record => {
              record.ore = res.data.find(resRecord => resRecord.id === record.oreId);
              return record;
            });
            this.showInfoNotification('Ores have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }
      },
      registerChange(scanResultResource) {
        if (!this.isNew(scanResultResource)) {
          scanResultResource.changed = true;
          // this.forceRerender();
        }
      },
      isNew(scanResultResource) {
        return scanResultResource.hasOwnProperty('new') && scanResultResource.new;
      },
      isChanged(scanResultResource) {
        return scanResultResource.hasOwnProperty('changed') && scanResultResource.changed;
      },
      isDeleted(scanResultResource) {
        return scanResultResource.hasOwnProperty('deleted') && scanResultResource.deleted;
      },
      showOreBrowser() {
        this.$refs.oreBrowser.showDialog();
      },
      addOre(ore) {
        if (!this.scanResultResources.find((scanResultResource) => scanResultResource.ore.id === ore.id)) {
          this.scanResultResources.push(
            {
              scanResultId: this.scanResultId,
              oreId: ore.id,
              ore: ore,
              volume: 0,
              new: true
            }
          )
        }
      },
      toggleDeleted(scanResultResource) {
        if (this.isNew(scanResultResource)) {
          this.scanResultResources = this.scanResultResources.filter((fScanResultResource) => fScanResultResource !== scanResultResource);
          this.forceRerender();
        } else {
          scanResultResource.deleted = !this.isDeleted(scanResultResource);
          this.forceRerender();
        }
      },
      getPrependIcon(scanResultResource) {
        if (this.isDeleted(scanResultResource)) {
          return 'bin'
        } else if (this.isChanged(scanResultResource)) {
          return 'pencil'
        } else if (this.isNew(scanResultResource)) {
          return 'asterisk'
        }
        return null;
      },
      saveScanResultResources() {
        this.scanResultResources.forEach((scanResultResource) => {
          let endpoint = '/ingame/world/scanresultresource';
          let event = null;
          let method = null;
          let payload = null;
          if (this.isNew(scanResultResource)) {
            event = 'scanResultResourceCreated';
            method = apiSecured.post;
            payload ={
              scanResultId: this.scanResultId,
              oreId: scanResultResource.oreId,
              volume: scanResultResource.volume
            }
          } else if (this.isChanged(scanResultResource)) {
            endpoint = '/ingame/world/scanresultresource/' + scanResultResource.id;
            event = 'scanResultResourceUpdated';
            method = apiSecured.patch;
            payload = [
              {
                op: 'replace',
                path: `/volume`,
                value: scanResultResource.volume
              },
            ];
          } else if (this.isDeleted(scanResultResource)) {
            endpoint = '/ingame/world/scanresultresource/' + scanResultResource.id;
            event = 'scanResultResourceDeleted';
            method = apiSecured.delete;
            payload = {};
          } else {
            return;
          }
          this.loading = true;
          this.inProgress++;
          method(endpoint, payload).then((res) => {
            this.showSuccessNotification(this.__('common.saved'));
            this.$emit(event);
            this.inProgress--;
            if (this.inProgress <= 0) {
              this.loading = false;
              this.inProgress = 0;
              this.refresh()
            }
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error);
            this.inProgress--;
            if (this.inProgress <= 0) {
              this.loading = false;
              this.inProgress = 0;
              this.refresh()
            }
          });
        })
      }
    }
  }
</script>