<template>
  <div class="edit-scan-result">
    <my-dialog :show="show" @close="close" v-if="originalRecord && record" :max-width="'1400px'">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('component.ingame.edit_scan_result.title')}}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content">
          <progress-linear v-if="loading"/>
          <div class="layout layout--wrap">
            <div class="flex xs3">
              <original-scan-result :original-scan-result="originalRecord"/>
            </div>
            <div class="flex xs3">
              <h2>{{__('common.edit_properties')}}</h2>
              <div class="layout layout--wrap">
                <div class="flex xs12">
                  <text-field
                      v-model="record.pos"
                      :label="'Pos'"
                      :error-messages="errors.pos"
                      maxlength="50"
                      :prepend-icon="isChanged('pos') ? 'pencil' : null"
                      @input="registerChange('pos')"
                  />
                </div>
                <div class="flex xs12">
                  <text-field
                      v-model="record.tile"
                      :label="__('entity.common.tile')"
                      :error-messages="errors.tile"
                      maxlength="50"
                      :prepend-icon="isChanged('tile') ? 'pencil' : null"
                      @input="registerChange('tile')"
                  />
                </div>
                <div class="flex xs12">
                  <label>{{ __('entity.common.stellar_object') }}</label>
                  <select-reference
                      v-model="record.stellarObjectId"
                      :endpoint="'/ingame/world/stellarobject'"
                      :nothing-selected-message="__('common.no_stellar_object_selected')"
                      :prepend-icon="isChanged('stellarObjectId') ? 'pencil' : ''"
                      @input="registerChange('stellarObjectId')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <text-area
                      v-model="record.description"
                      :label="__('entity.common.description')"
                      :error-messages="errors.description"
                      :prepend-icon="isChanged('description') ? 'pencil' : null"
                      @input="registerChange('description')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <div class="btn btn--success" :loading="loading" :disabled="changedProperties.length === 0"
                       @click="update" v-slashes>{{ __('common.save_changes') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="flex xs6">
              <edit-scan-result-resources ref="editScanResultResources" :scan-result-id="record.id"></edit-scan-result-resources>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import { localizationMixin } from '../../mixins/localization';
  import OriginalScanResult from './EditScanResult/OriginalScanResult';
  import EditScanResultResources from "./EditScanResult/EditScanResultResources";
  import {editFormMixin} from "../../mixins/editForm";

  export default {
    components: {
      EditScanResultResources,
      OriginalScanResult
    },
    mixins: [localizationMixin, editFormMixin],
    data: () => ({
      endpoint: '/ingame/world/scanresult',
      emptyRecord: {
        id: null,
        tile: 0,
        pos: '',
        description: '',
        stellarObjectId: null,
        createdAt: '---',
        updatedAt: '---'
      },
    }),
  }
</script>